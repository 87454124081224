import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Navbar from './Navbar';
import Profile from './Profile';
import Homepage from './Homepage/Homepage';
import Contact from './Contact';
import Enquiry from './Homepage/Homepage';
import Footer from './Footer';
import Led from './Products/Led/Product-led';
import Fp from './Products/Flameproof/Product-FP';
import Solar from './Products/Solar/Product-solar';
import Domestic from './Products/Domestic/Product-domestic';
import Flowmeter from './Products/Flowmeter/Product-flowmeter';
import Instr from './Products/Instruments/Product-instruments';
const Main = ()=>(
    
    <div className="main">
        <Navbar/>
        <Switch>
            <Route path="/home" component={Homepage} />
            <Route path="/profile" component={Profile}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/Enquiry" component={Enquiry}/>
            <Route path="/led" exact component={Led}/>
            <Route  path="/products/flameproof" component={Fp}/>
            <Route path="/solar" component={Solar}/>
            <Route path="/domesticproducts" component={Domestic}/>
            <Route path="/flowmeterproducts" component={Flowmeter}/>
            <Route path="/instrumentsproducts" component={Instr}/>
            <Redirect to="/home"/>
        </Switch>
        <Footer/>
        
    </div>


);

export default Main;