import React from 'react'
import Mailer from './mailer'
import './Form.css';

const Contact = () => (
    <div className="col-12">
        
        <div className="row">
            <div className="row mx-auto mb-5" >
                <Mailer/>
            </div>
            
            <div className="contact-section col-12">
                <div class="row contact-container mt-5 mb-5">
                    <div class="left"></div>
                    <div class="right">
                    <div class="contact-content">
                        <h1 style={{fontWeight:"bold", fontFamily:"TimesNewRoman"}}>CONTACT US</h1>
                        <div className="col-12 mt-4 ml-3 align-content-center">
                            <h3><i className=" fa fa-envelope mt-3 "> </i> Shoot your queries at:</h3>
                            <h4>info@sudhirgroup.co.in</h4>
                            <h3><i className=" fa fa-phone  mt-3"></i> Call us on: </h3>
                            <h4>+91-9887103689</h4>
                            <h4>+91-6367927635</h4>
                            <h3><i className=" fa fa-address-card  mt-3"></i> Meet us:</h3>
                            <h4>4/260, Rajasthan Housing Board, U.I.T., Bhiwadi, Rajasthan 301019</h4>

                        </div>
                        
                    </div>
                    </div>
                </div>
                
            </div>
            
            

        </div>

        
        

    </div>
);

export default Contact;