import React from 'react';

import About1 from '../Assets/Image/About/About0.jpg';
import About3 from '../Assets/Image/About/About3.jpg';
import About4 from '../Assets/Image/About/About4.png';
import Clients from './Clients'
import { Carousel } from 'react-bootstrap';

const line={
  height: '5px',
background: 'white'
}

const About =() =>{
  return(
    
    <div>
      <Carousel fade>
        <Carousel.Item interval={1500}>
          <img
            className="  d-block w-100" style={{height:'60vh', width:'100vh'}}
            src={About1}
            alt="First slide"
          />
          
        </Carousel.Item>
        


        <Carousel.Item interval={1500}>
          <img
            className="d-block w-100" style={{height:'60vh'}}
            src={About3}
            alt="Second slide"
          />
        </Carousel.Item>
        
      </Carousel>
    
      <div className="col-12 about-main">
        
        <div className="row ">
          <div className="row m-5 mt-5 ">
            
                <span className="about-sudhir text-capitalize mx-auto">Sudhir</span>
                <p>
                  is one of the trusted leading company that offers wide range of flameproof light and products, solar products (Solar street lights, Solar lartern, Solar home lighting system, solar water heating system, Solar power plants) ,LED's (LED street lights, down lights, outdoor lights and indoor lights) and also industrial instruments. 
                  There is a wide spectrum of customers satisfied with the our product. 
                  
                </p>
                <p>This company is working from India's famous industrial hotspot-BHIWADI, RAJASTHAN.
                  Sudhir was established in 2013 with a sole purpose of contributing in sustainable energy.
                  Ever since then it never turned back and continued to overcome challenges coming its ways. 
                  Sudhir is proud on its journey and also to have renouned brands as their customers from all over india.Sudhir is blessed to have proficient staff members and incredibly talanted leaders.<br/>Customers satisfication is our foremost concern and we're delightful to serve best of our abilities.
                </p> 
            
          </div>

          <div className="row mb-5 mx-auto">
            <div className="col-12 mx-auto" >
                <img className="about-circle-img col-12 " alt="about" src={About4} />
            </div>
            
          </div>
          
        </div>
        <div className="row m-5 my-auto ">
          <p>SUDHIR SOLAR ENERGY PVT. LTD. has been providing their Solar and LEDs products in Rajasthan, Haryana, and Uttar Pradesh, assam and for many more customers across the nation. For a while now, alternative sources of energy have been to a great mileage in use to a wide range of our population. We offer a complete solar power plant as well as roof top system for our clients as per their requirements.  Sudhir is working satisfactory for a long time in the field of energy sector. We ensure the energy saving by the replacing the conventional power consuming lighting from LEDs lighting which delivers the best outcome. Please going through our website should you require any information on our products, please feel free to contact us. <br/><br/> <b> We are proud to be: </b>
               
               <br/><br/>AN ISO 9001:2008 and CERTIFIED COMPANY
               
              <br/>MSME Certified
              <br/>Approved vendor of Power Grid Corporation	
              <br/>Approved Suppler in GAIL
              <br/>Approved Suppler in MES (Military Engineering services)	 
              <br/>Approved vendor of Top Multi National Companies
          </p>
          
            
          
          
        </div>

        <div className=" row mt-5 cust">
          <h1 className="mx-auto" style={{fontFamily: 'Trocchi', fontSize: '35px', fontWeight:'bold'}}>OUR HAPPY CUSTOMERS</h1>
        </div>
          <div className=" row  mb-5 cust">
            <div className="mx-auto col-8 " style={line}>  </div>
          
          </div>
          
        <Clients/>
        <div className="row mt-5 "></div>
      </div>
    </div>
    
  );
}
export default About;
