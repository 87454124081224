import React,{useState} from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import Complogo from '../Assets/Image/Homepage/final logo.ico'

/*scroll color change*/
function Navb() {
  const[navbar,setNavbar] =useState(false);

  const changeBackground = () => {
    if(window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeBackground);
  return(
    
    <Navbar  sticky="top"  className={navbar ? 'navbar active' : 'navbar'}  expand="lg" >
    <Navbar.Brand className="ml-5 logo" href="#home">
      <img src={Complogo} style={{maxWidth:'150px',maxHeight:'75px'}} className="logo" alt="Sudhir solar" />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{color:"white"}}><i class="fas fa-bars"></i></Navbar.Toggle>
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav className=" nav-content" >
      <Nav.Link className="ml-4" href="/home" style={{color:'white'}}>Home</Nav.Link>
      <Nav.Link className="ml-4" href="/profile" style={{color:'white'}}>Profile</Nav.Link>
      <Nav.Link className="ml-4" href="/contact" style={{color:'white'}}>Contact</Nav.Link>
      <Nav.Link className="ml-4"  href="/products/flameproof" style={{color:'white'}}>Products</Nav.Link>
      <Nav.Link className="ml-4" target="_blank" href="https://drive.google.com/file/d/1Hs3UZ9D_YeN-J9e9vjUVRBdWkBpbCCfq/view?usp=sharing" style={{color:'white'}}>Catalogue</Nav.Link>
    </Nav>
    </Navbar.Collapse>
      
    </Navbar>
  );
}
export default Navb;