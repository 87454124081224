
import React from 'react';


import Intro from '../../Assets/Image/Homepage/Homepageintro.svg';
import Aboutcards from './Aboutcards';

const divStyle = {
    Height:'80%',
    minWidth:'100%',
    borderBottomLeftRadius:'50%'
    
};

const Homepage = () => (
    <div className="homepage-main col-12">

        <div className="row" style={{background:""}}>
            <div className="col-12" >
                <div className="row" >
                    <img src={Intro}  alt="introduction" style={divStyle}/>
                </div>
                <div className="row mt-5 mb-5">
                    <div className="col-12 ">
                        <Aboutcards/>
                    </div>
                </div>
                
      
                
            </div>
        </div>
        
    </div>
);

export default Homepage;