import React from 'react'
import ProductCards from '../Productcards'
import productfirstimage from '../../../Assets/Image/Products/Domestic/Solarhome.png'
import productsecondimage from '../../../Assets/Image/Products/Domestic/Solarlarten.png'
import productthirdimage from '../../../Assets/Image/Products/Domestic/Waterlevel.png'

const Dsolar = () => {
    var dsolar1 = 
    {   id: 0,
        Producttitle: "Solar home inverter",
        Productdescription:"SSE SHI 7.5 Ah/12 Ah"
    }
    
    var dsolar2 =
    {   id: 1,
        Producttitle: "Solar Lartern",
        Productdescription:"3 watt LED/ 4.5 Ah"
    }
    var dsolar3 =
    {   id: 2,
        Producttitle: "Water Level Indicator",
        Productdescription:"SSE WLI 4 set point"
    }
    
    
    ;




    return(
        <div className="col-12">
            <h1 style={{fontFamily: "Times new roman", fontWeight: "bold"}}> Domestic products</h1>
            <div className="row mt-5 mb-3">
                 <div className="col-12 col-md-4 mt-5 mx-auto">
                    <ProductCards productinfo={dsolar1} productimage = {productfirstimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={dsolar2} productimage = {productsecondimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={dsolar3} productimage = {productthirdimage} />
                </div>
                
                    
            </div>
            <br/>
            <a style={{fontWeight:"bold", color:"#0099ff"}} >  If you are unable to see other products categories in right side then Scroll down.  </a>
        </div>
           
        
    );
    
}

export default Dsolar;
