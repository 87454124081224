import React from 'react'
import ProductCards from '../Productcards'
import productfirstimage from '../../../Assets/Image/Products/Instruments/cable.jpg'
import productsecondimage from '../../../Assets/Image/Products/Instruments/Side.jpg'
import productthirdimage from '../../../Assets/Image/Products/Instruments/Magnetic.jpg'
import productfourthimage from '../../../Assets/Image/Products/Instruments/Glass.jpg'
import productfifthimage from '../../../Assets/Image/Products/Instruments/Automation.jpg'
import productsixthimage from '../../../Assets/Image/Products/Instruments/waterlevelsensor.jpg'
import productseventhimage from '../../../Assets/Image/Products/Instruments/minifloatswitch.jpg'
import producteigthimage from '../../../Assets/Image/Products/Instruments/Submersible.jpg'
import productninthimage from '../../../Assets/Image/Products/Instruments/Float.jpg'
const Instr = () => {
    
    var instr1 =
    {
        id: 2,
        Producttitle: "Cable Float Level Switch",
        Productdescription:"Level controlling for Water/sewage water"
    }

    var instr2 =
    {
        id: 2,
        Producttitle: "Side mounted level switch",
        Productdescription:"Level controlling for water/oil"
    }
    var instr3 =
    {
        id: 3,
        Producttitle: "Magnetic float sensor",
        Productdescription:"Level controlling for water/hot water/ diesel"
    }
    var instr4 =
    {
        id: 3,
        Producttitle: "Glass Tube Level Gauge",
        Productdescription:"Level indication for oil/ diesel/water"
    }
    var instr5 =
    {
        id: 3,
        Producttitle: "Automation of Water & Diesel ",
        Productdescription:""
    }
    
    var instr6 =
    {
        id: 3,
        Producttitle: "Miniature sensor",
        Productdescription:"Level controlling for water/Oil"
    }

    var instr7 =
    {
        id: 3,
        Producttitle: "Mini Float Switch",
        Productdescription:"Level controlling for water"
    }
    var instr8 =
    {
        id: 3,
        Producttitle: "Submersible level transmitter",
        Productdescription:"Level controlling and indication for water"
    }

    var instr9 =
    {
        id: 3,
        Producttitle: "Float & Chord Level Indicator",
        Productdescription:"Level indication for oil"
    }
    
    ;




    return(
        <div className="col-12 ">
            <h1 style={{fontFamily: "Times new roman", fontWeight: "bold"}}> Process intrumentation and automation</h1>
            <div className="row mx-auto mt-5 mb-3">
                 <div className="col-12 col-md-4 mt-5 mx-auto">
                    <ProductCards productinfo={instr1} productimage = {productfirstimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={instr2} productimage = {productsecondimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={instr3} productimage = {productthirdimage} />
                </div>
                
                    
            </div>
            <div className="row mx-auto mt-5 mb-3">
                 <div className="col-12 col-md-4 mt-5 mx-auto">
                    <ProductCards productinfo={instr4} productimage = {productfourthimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={instr5} productimage = {productfifthimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={instr6} productimage = {productsixthimage} />
                </div>
                
                    
            </div>
            <div className="row mx-auto mt-5 mb-3">
                 <div className="col-12 col-md-4 mt-5 mx-auto">
                    <ProductCards productinfo={instr7} productimage = {productseventhimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={instr8} productimage = {producteigthimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={instr9} productimage = {productninthimage} />
                </div>
                
                    
            </div>
            <br/>
            <a style={{fontWeight:"bold", color:"#0099ff"}}>  If you are unable to see other products categories in right side then Scroll down.  </a>
            
       
        </div>
        
    );
    
}

export default Instr;
