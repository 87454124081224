import React from 'react';
import Card from "react-bootstrap/Card";


const Aboutcards= () => (
    <div className="col-12">
            <div className="row  justify-content-center">
                <div className="col-12 col-md-4 m-3 ">
                    <Card className="homepage-cards mx-auto" style={{ width:'100%', borderRadius:"40px" }}>
                        <Card.Body className="pd-2"  >
                            <Card.Title className="text-center" style={{fontFamily: 'Trocchi', fontSize: '35px', fontWeight:'bold'}}>ABOUT SUDHIR</Card.Title>
                            <Card.Text style={{fontSize: 'large'}}>
                            Sudhir is one of the trusted leading company for flameproof lights, Leds lights, industrial instruments and solar products in India.Sudhir was established in 2013 with a sole purpose of contributing in sustainable energy.<br/>
                            Ever since then it never turned back and continued to overcome challenges coming its ways. <br/>From working in Rajasthan to get business from different corners of country, we are delightful to see our startegic growth. We know there's  still a long way to go but we believe in our dedicated team and ability to optimize our working.  
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-12 col-md-4 m-3">
                        <Card className="homepage-cards mx-auto" style={{  width:'100%', borderRadius:"40px" }}>
                            <Card.Body>
                                <Card.Title style={{fontFamily: 'Trocchi', fontSize: '35px', fontWeight:'bold'}}>Why you should choose SUDHIR?</Card.Title>
                                
                                <Card.Text style={{fontSize: 'large'}}>
                                We are a group that believes in listening and understanding the customer's perspective and we ensure customer satisfaction by providing competitive prices and services best of our abilities. We are reliable and holds years of experience doing business of Industrial instruments, LEDs , Solar products. We manage all our activities with timed precision to achieve excellence in every endeavor undertaken by us.
                                </Card.Text>
                                
                            </Card.Body>
                        </Card>
                </div>
            </div>
    </div>
    

);

export default Aboutcards;
