import React from 'react'
import ProductCards from '../Productcards'
import productfirstimage from '../../../Assets/Image/Products/Flowmeter/Digital.jpg'
import productsecondimage from '../../../Assets/Image/Products/Flowmeter/Electro.jpg'
import productthirdimage from '../../../Assets/Image/Products/Flowmeter/Piezometer.jpeg'

const Flowmeter = () => {
    var flow1 = 
    {   id: 0,
        Producttitle: "Digital Diesel Flow Meter",
        Productdescription:""
    }
    
    var flow2 =
    {   id: 1,
        Producttitle: "Electro Magnetic Flow Meter",
        Productdescription:""
    }
    var flow3 =
    {   id: 1,
        Producttitle: "Peizometer",
        Productdescription:""
    }
    
    ;




    return(
        <div className="col-12">
            <h1 style={{fontFamily: "Times new roman", fontWeight: "bold"}}> Flowmeters</h1>
            <div className="row mt-5 mb-3">
                 <div className="col-12 mt-5 col-md-4 mx-auto">
                    <ProductCards productinfo={flow1} productimage = {productfirstimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={flow2} productimage = {productsecondimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={flow3} productimage = {productthirdimage} />
                </div>
                
                    
            </div>
            <br/>
            <a style={{fontWeight:"bold", color:"#0099ff"}}>  If you are unable to see other products categories in right side then Scroll down.  </a>
        </div>
           
        
    );
    
}

export default Flowmeter;
