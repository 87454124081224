import React from 'react'
import Card from "react-bootstrap/Card";

const ProductCards = (props) => {
    return(
        <div className="col-12">
            <Card className="pt-5" style={{  minHeight:'28rem', maxHeight:'28rem', fontWeight:'bold', borderRadius:"20px",boxShadow: '5px 10px #e6fff2',borderColor:'green'}}>
                <Card.Img style={{  minHeight:'13rem', maxHeight:'13rem'}} className="Product-img" variant="top" src={props.productimage} />
                <Card.Body>
                    <Card.Title><p>{props.productinfo.Producttitle}</p></Card.Title>
                        <Card.Text>
                            {props.productinfo.Productdescription}
                        </Card.Text>
                        
                </Card.Body>
            </Card>
        </div>
    );
    
}

export default ProductCards;
