import React from 'react'
import ProductCards from '../Productcards'
import productfirstimage from '../../../Assets/Image/Products/LED/Highbay trad.png'
import productsecondimage from '../../../Assets/Image/Products/LED/Streetlight.png'
import productthirdimage from '../../../Assets/Image/Products/LED/LEDflood.png'
import productfourthimage from '../../../Assets/Image/Products/LED/FP-UFO.png'
import productfifthimage from '../../../Assets/Image/Products/LED/Surfacelight.jpg'
import productsixthimage from '../../../Assets/Image/Products/LED/Ceilinglight.JPG'
const Led = () => {
    
    var led1 =
    {
        id: 2,
        Producttitle: "High Bay light",
        Productdescription:"SSE HL traditional 100 Lumen/watt."
    }

    var led2 =
    {
        id: 2,
        Producttitle: "Led Street light",
        Productdescription:"SSE SL 100 Lumen/watt"
    }
    var led3 =
    {
        id: 3,
        Producttitle: "LED floodlight",
        Productdescription:"SSE FL 100 Lumen/watt"
    }
    var led4 =
    {
        id: 3,
        Producttitle: "LED Bay light UFO",
        Productdescription:"SSE HL UFO 100 Lumen/watt"
    }
    var led5 =
    {
        id: 3,
        Producttitle: "LED panel surface light",
        Productdescription:"SSE CL Surface and Alumunium slim"
    }
    var led6 =
    {
        id: 3,
        Producttitle: "LED panel light ",
        Productdescription:" 2x2 Comercial Ceiling light  SSE CL"
    }
    
    ;




    return(
        <div className="col-12 ">
            <h1 style={{fontFamily: "Times new roman", fontWeight: "bold"}}> LEDs products</h1>
            <div className="row mx-auto  mb-3">
                 <div className="col-12 col-md-4 mt-5 mx-auto">
                    <ProductCards productinfo={led1} productimage = {productfirstimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={led2} productimage = {productsecondimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={led3} productimage = {productthirdimage} />
                </div>
                
                    
            </div>
            <div className="row mx-auto  mb-3">
                 <div className="col-12 col-md-4  mt-5 mx-auto">
                    <ProductCards productinfo={led4} productimage = {productfourthimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={led5} productimage = {productfifthimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={led6} productimage = {productsixthimage} />
                </div>
            </div>
            <br/>
            <a style={{fontWeight:"bold", color:"#0099ff"}}>  If you are unable to see other products categories in right side then Scroll down.  </a>
        </div>
        
    );
    
}

export default Led;
