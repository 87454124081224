
/*data for sidebar products*/
export const Sidebardata = [
    {
        title: "LED's Flameproof products",
        link: "products/flameproof"
        
    },


    {
        title: "LED's lights",
        link: "/led"
        
    },


    {
        title: "Solar products",
        link: "/solar"
    },


    {
        title: "Process intruments and automation",
        link: "/instrumentsproducts"
    },

    {
        title: "Flowmeter",
        link: "/flowmeterproducts"
    },


    {
        title: "Domestic products",
        link: "/domesticproducts"
    }
    
    
]


