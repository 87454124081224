
import React from 'react'
import ScrollUpButton from "react-scroll-up-button";
import { Redirect } from "react-router-dom";
import {Sidebardata} from './Sidebardata'

const sidebarStyle = {
    backgroundColor: '#4B6F44',
    width:'100vh',
    left:'0',
    height:'80vh',
    color:'white',
    fontFamily: 'Patrick Hand SC',
    fontWeight:'bold',
    
    
};

const SidebarlistStyle={
   
    height:'auto',
    padding:'0px',
    width:'100%'

}

const SidebarrowStyle ={
    width:'100%',
    height:'70px',
    
    listStyle:'none',
    margin:'0',
    display:'flex',
    color:'white',
    justifyContent:'center',
    alignItems:'center',
}

const line={
    height: '5px',
  background: 'white'
}



function Sidebar() {
    return ( 
        <div className="col-12 products-Sidebar"  style={sidebarStyle} >
            <div className="row mt-5 pt-5">
                <div className="col-12">
                    <h2 className="mx-auto" style={{fontWeight:'bold'}} >OUR PRODUCTS</h2>
                    <div className="mx-auto" style={line}>  </div>
                </div>
            </div>
            <ul className="Sidebarlist" style={SidebarlistStyle}>
                {Sidebardata.map((val,key)=>{
                    return(
                            
                            <li style={SidebarrowStyle} className="sidebar-row" id={window.location.pathname == val.link ? "active" : ""} 
                            key={key} 
                             onClick={()=>
                             {window.location.pathname= val.link;
                                <Redirect to={val.link} />
                            }}>
                                {"  "} 
                                <h5> {val.title}</h5> {"  "}
                                
                            </li>
                            
                    )
                })}
            </ul>
        
        </div>);
    
}

export default Sidebar;
