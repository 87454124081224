import React from 'react'
import ProductCards from '../Productcards'
import productfirstimage from '../../../Assets/Image/Products/Solar/solarstreetlights.jpg'
import productsecondimage from '../../../Assets/Image/Products/Solar/solarwaterheat.png'
import productthirdimage from '../../../Assets/Image/Products/Solar/SSP.jpg'


const Solar = () => {
    var solar1 = 
    {   id: 0,
        Producttitle: "Solar street lights",
        Productdescription:"SSE SSL All in one/ two part"
    }
    
    var solar2 =
    {   id: 1,
        Producttitle: "Solar water heating system",
        Productdescription:"SSE WHS"
    }

    var solar3 =
    {
        id: 2,
        Producttitle: "Solar power plant",
        Productdescription:"SSE SPP On-grid and Off-grid"
    };




    return(
        <div className="col-12">
            <h1 style={{fontFamily: "Times new roman", fontWeight: "bold"}}> Solar products</h1>
            <div className="row mt-5 mb-3">
                 <div className="col-12 col-md-4 mt-5  mx-auto">
                    <ProductCards productinfo={solar1} productimage = {productfirstimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={solar2} productimage = {productsecondimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                    <ProductCards productinfo={solar3} productimage = {productthirdimage} />
                </div>
                    
            </div>
            <br/>
            <a style={{fontWeight:"bold", color:"#0099ff"}}>  If you are unable to see other products categories in right side then Scroll down.  </a>
        </div>
           
        
    );
    
}

export default Solar;
