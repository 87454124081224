import React from 'react';
    const Footer = () =>(
        <footer class="footer">
            <div class="footer-left col-md-4 col-sm-6">
                <p class="about">
                <span> About the company</span> Sudhir is one of the trusted leading manufactures and dealers of flameproof lights, Led's lights, industrial instruments and solar products in India. Sudhir was established in 2013 with a sole purpose of contributing in sustainable energy.
                Ever since then it never turned back and continued to overcome challenges coming its ways.
                From working in Rajasthan to get business from different corners of country, we are delightful to see our startegic growth. 
                </p>
                
            </div>
            <div class="footer-center col-md-4 col-sm-6">
                <div>
                <i class="fa fa-map-marker"></i>
                <p><span> 4/260, Rajasthan Housing Board, U.I.T.</span> Bhiwadi, Rajasthan 301019</p>
                </div>
                <div>
                <i class="fa fa-phone"></i>
                <p> +91-9887103689, </p>
                <p> +91-6367927635</p>
                </div>
                <div>
                <i class="fa fa-envelope"></i>
                <p> info@sudhirgroup.co.in</p>
                </div>
            </div>
            <div class="footer-right col-md-4 col-sm-6">
                
                <p class="menu">
                <a href="/home"> Home</a> |
                <a href="/profile"> About</a> |
                <a href="/products/flameproof"> Products</a> |
                <a href="https://drive.google.com/file/d/1Hs3UZ9D_YeN-J9e9vjUVRBdWkBpbCCfq/view?usp=sharing"> Catalogue</a> |
                <a href="/contact"> Contact</a>
                </p>
                <p class="name"> Sudhir energy &copy; 2013. All rights are reserved.</p>
            </div>
        </footer>
    );

    export default Footer;
        