import emailjs from "emailjs-com";
import './Form.css';
import contact from '../Assets/Image/Contact/contact-us.jpg'


const Mailer = () => {
  function sendEmail(e) {
    e.preventDefault();
   
    emailjs.sendForm(
      "service_xlzu2es",
      "template_zqjoat3",
      e.target,
      "user_AiovgkCguEFTQdJzrkTeb"
    ).then(res=>{
        console.log(res);
    }).catch(err=> console.log(err));
    alert("Your enquiry submitted sucessfully. Click ok to continue.");
    window.location.reload();
  }
  return (
    <div className="col-12">
      <div className="form-contain row mx-auto" style={{boxShadow: '20px 30px 20px 30px #e6fff2',borderColor:'green'}}>
        <div className="formimage-holder">
          <img className="col-12" src={contact} alt='contact image'/>
        </div>
        
        <div className="mx-auto">
          <h1 className="mx-auto mt-5" style={{fontWeight:"bold", alignItems:"center",fontFamily:"TimesNewRoman", color:"rgb(26, 153, 126)"}}>Contact Form</h1>
          <form
            className="row " style={{marginRight:"20px"}}
            onSubmit={sendEmail}
          >
            <label className="mt-5" style={{fontWeight:"bold"}}>Name</label>
            <input type="text" name="name" className="form-control form-inputs" />

            <label className="mt-5" style={{fontWeight:"bold"}}>Email</label>
            <input type="email" name="user_email" className="form-control form-inputs" />

            <label className="mt-5" style={{fontWeight:"bold"}}>Message</label>
            <textarea name="message" rows="4" className="form-control form-inputs" />
            <input
              type="submit"
              value="Send"
              className="form-control form-input-btn btn btn-success"
              style={{ marginTop: "30px" }}
            />
          </form>

        </div>
        
      </div>
    </div>
  );
};

export default Mailer;
