import React from 'react'
import { Carousel } from 'react-bootstrap';
import Cust from '../Assets/Image/About/Customer.jpg';


const Clients = () => {
  return (
    <div >
      <Carousel fade>
        <Carousel.Item >
          <img
            className="  d-block w-100" style={{height:'90vh', width:'80vh'}}
            src={Cust}
            alt="First slide"
          />
          
        </Carousel.Item>
      </Carousel>
      
    </div>
  )
}

export default Clients
