import React from 'react'
import ProductCards from '../Productcards'
import productfirstimage from '../../../Assets/Image/Products/FP/Fptubelight.png'
import productsecondimage from '../../../Assets/Image/Products/FP/FP-1.png'
import productthirdimage from '../../../Assets/Image/Products/FP/FP-2.png'
import productfourthimage from '../../../Assets/Image/Products/FP/FP-3.png'
import productfifthimage from '../../../Assets/Image/Products/FP/Switch.jpg'
import productsixthimage from '../../../Assets/Image/Products/FP/FPjunctionbox.png'


const Flameproof= () => {
    var fp1 = 
    {   id: 0,
        Producttitle: "Flameproof Tubelight",
        Productdescription:"1x20 watt and 2x20 watt (LED) "
    }
    
    var fp2 =
    {   id: 1,
        Producttitle: "Flameproof floodlight",
        Productdescription:"45 W/60 W/120 W"
    }

    var fp3 =
    {
        id: 2,
        Producttitle: "Flameproof Bay light",
        Productdescription:""
    }

    
    var fp4 =
    {
        id: 3,
        Producttitle: "Flameproof Well glass",
        Productdescription:""
    }

    var fp5 =
    {
        id: 4,
        Producttitle: "Flameproof switch and socket",
        Productdescription:"16 A/32 A/63 A"
    }

    var fp6 =
    {
        id: 5,
        Producttitle: "Flameproof junction box",
        Productdescription:"100 mm dia/40 Way"
    }
    
    ;




    return(
        <div className="col-12 ">
            
            <h1 style={{fontFamily: "Times new roman", fontWeight: "bold"}}> Flameproof products</h1>
            
            <div className="row mx-auto mt-5 mb-3">
                 <div className="col-12 col-md-4 mt-5 mx-auto">
                    <ProductCards productinfo={fp1} productimage = {productfirstimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                        <ProductCards productinfo={fp2} productimage = {productsecondimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                    <ProductCards productinfo={fp3} productimage = {productthirdimage} />
                </div>
                    
            </div>
            <div className=" mx-auto row mt-2 mb-5">
                
                <div className="col-12 col-md-4 mt-5 mx-auto">
                    <ProductCards productinfo={fp4} productimage = {productfourthimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                    <ProductCards productinfo={fp5} productimage = {productfifthimage} />
                </div>
                <div className="col-12 col-md-4 mt-5 mx-auto">
                    <ProductCards productinfo={fp6} productimage = {productsixthimage} />
                </div>
                        
            </div>
            <br/>
            <a style={{fontWeight:"bold", color:"#0099ff"}}>  If you are unable to see other products categories in right side then Scroll down.  </a>
            
       
        </div>
        
    );
    
}

export default Flameproof;
