import React from 'react'
import Sidebar from '../Sidebar'
import Flowmeter from './Flowmeter'


function Product() {
    return (
        <div className="col-12">
            
            <div className="row mb-5">
                
                <div className="col-12 col-md-6 col-lg-9 mt-5 mb-5" >
                    <Flowmeter/>
                </div>
                <div className="col-12 col-md-12 col-lg-3">
                    <Sidebar/>
                </div>
            </div>
            
        </div>
    )
}

export default Product
